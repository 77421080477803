<template>
  <v-card flat class="product-view">
    <v-card-title class="pa-1 pt-0">
      <barcode-scanner v-model="scanCode" :search="scanCode" :scannerPayload="scannerPayload"></barcode-scanner>
      <!-- <v-text-field solo dense hide-details ref="scanFocus" v-model="scanCode" id="scanCode"
      @click="selectContent" autocomplete="off"
      @change="getRespectiveProductOrder"
      :placeholder="$t('message.whsDashboard.scanText')"></v-text-field> -->
    </v-card-title>
    <v-card-text class="px-0">
      <div class="text-center" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-if="productMeta">
        <v-row>
          <v-col :cols="windowSize < 360 ? '12' : '4'" class="px-0 py-0 font-weight-bold">{{ $t('message.product.barcode') }}: {{ productMeta.productbarcode }}</v-col>
          <v-col :cols="windowSize < 360 ? '12':'8'" :class="windowSize < 360 ? '' : 'text-end'" class="py-0 px-0 font-weight-bold">{{ $t('message.product.supplierartno') }}: {{ productMeta.supplierartnumber }}</v-col>
        </v-row>
        <v-row>
          <v-col :cols="windowSize < 360 ? '12' : '4'" class="pa-1 pt-0 pb-0">{{ $t('message.order.products') }}: {{ productMeta.productname }}</v-col>
          <!-- <v-col cols="6" class="pa-1 pt-0 pb-0">HS: {{ productMeta.producths }}</v-col>
          <v-col cols="6" class="pa-1 pt-0 pb-0">COO: {{ productMeta.productcoo }}</v-col> -->
          <v-col :cols="windowSize < 360 ? '12' : '8'" :class="windowSize < 360 ? '' : 'text-end'" class="pa-1 pt-0 pb-0">{{ $t('message.product.supplier') }}: {{ productMeta.suppliername }}</v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="6" class="pa-1 highlight font-weight-bold">{{ $t('message.product.minQty') }}: {{ productMeta.productminqty | replaceDotWithComma }}</v-col>
          <v-col cols="6" class="pa-1 highlight font-weight-bold">{{ $t('message.product.orderQty') }}: 0</v-col>
        </v-row>
        <v-row class="highlight ma-1" v-for="location in listOfLocations" :key="location.locationid">
          <v-col cols="6" class="pa-1">
            {{ location.locationname }}
          </v-col>
          <v-col cols="6" class="pa-1">
            {{ $t('message.product.qty') }}: {{ location.onstock | replaceDotWithComma }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0" v-for="(order, index) in listOfCustomerOrders" :key="`${index}_customer`">
            <customer-item :isList="false" :model="order"></customer-item>
          </v-col>
          <v-col cols="12" class="pa-0 pt-0" v-for="(order, index) in listOfSupplierOrders" :key="`${index}_supplier`">
            <supplier-item type="supplier" :isList="false" :model="order"></supplier-item>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="text-center">
          <v-img v-if="!loading" class="mx-auto" width="40%" height="100%" src="@/assets/noDataAvailable.jpg"></v-img>
          <h3 v-if="!loading">{{ $t('message.product.noProductFound') }}</h3>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
export default {
  data () {
    return {
      windowSize: window.screen.availWidth,
      scanCode: '',
      productMeta: null,
      listOfLocations: [],
      listOfCustomerOrders: [],
      listOfSupplierOrders: [],
      loading: false,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.whsDashboard.scanText',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      }
    }
  },
  created () {
    if (this.$route.query.qr) {
      this.scanCode = this.$route.query.qr.replace('-', '&')
      this.getRespectiveProductOrder()
    }
  },
  computed: {
    ...mapGetters(['getScannedCode', 'getHostRefApi', 'userId'])
  },
  components: {
    'customer-item': () => import('@/components/Order/CustomerItem'),
    'supplier-item': () => import('@/components/Order/OrderItem'),
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    requestAnimationFrame(() => {
      if (this.$refs.scanFocus) {
        this.$refs.scanFocus.focus()
      }
    })
    this.scanCode = this.getScannedCode || ''
    this.getRespectiveProductOrder()
  },
  methods: {
    getRespectiveProductOrder () {
      if (this.scanCode) {
        this.loading = true
        hostAppApi.post(`${this.getHostRefApi}products/get_product_info/${this.scanCode}?user_id=${this.userId}`, {})
          .then((response) => {
            if (response.data) {
              const { prouductinfo, customerorders, supplierorders } = response.data
              this.productMeta = prouductinfo ? prouductinfo[0] : null
              this.listOfLocations = prouductinfo
              this.listOfCustomerOrders = customerorders
              this.listOfSupplierOrders = supplierorders
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    selectContent () {
      if (this.$refs.scanFocus) {
        setTimeout(() => {
          document.getElementById('scanCode').select()
        }, 50)
      }
    }
  }
}
</script>
